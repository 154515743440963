import React from "react"
import Helmet from "react-helmet";

import Layout from "../components/layout.js"
import Wrapper from "../components/wrapper.js"
import ContactModal from "../components/contact-modal.js";
import HeaderSimple from "../components/header-simple";

import BannerSimple from "../components/sections/banner-simple";
import Footer from "../components/sections/footer.js"

import ValueForBoth from "../components/sections/value-for-both.js";
import HowItsPossible2 from "../components/sections/how-its-possible-2.js";
import ITSpecialities from "../components/sections/it-specialities.js";


import "../assets/css/bootstrap.min.css";
import "../assets/css/themify-icons.css";
import "../assets/css/flaticon-set.css";
import "../assets/css/magnific-popup.css";
import "../assets/css/owl.carousel.min.css";
import "../assets/css/owl.theme.default.min.css";
import "../assets/css/animate.css";
import "../assets/css/bootsnav.css";
import "../assets/css/responsive.css";

import "../styles/index.scss";

import store from "../state/createStore";
import { Provider } from "react-redux";


const CareersPage = () => (
    <Provider store={store()}>
        <Layout>
                <Helmet>
                    <title>SOMMER - medical consultants for software development businesses</title>
                    <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
                    <meta charset="utf-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="SOMMER - medical consultants for software development businesses" />
                    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600;700;800&display=swap" rel="stylesheet" />
                </Helmet>

                <Wrapper>
                    <ContactModal formRole="doctor" />

                    <HeaderSimple />

                    <BannerSimple titleStart="Meet the " titleBold="Software businesses" formRole="doctor" />

                    <HowItsPossible2 />
                    <ValueForBoth />
                    <ITSpecialities />


                    <Footer />
                </Wrapper>
        </Layout>
    </Provider>
)

export default CareersPage